import defaultAxios, { AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import PreferenceCookie from '@services/preferences-cookie-service';
import Cookies from 'universal-cookie';
import { AppCookies } from '@enums/cookies';
import { Preferences } from '@interfaces/models/preferences';

const axios = defaultAxios.create({
  timeout: 10000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    ...(typeof window === 'undefined' && {
      'Accept-Encoding': 'gzip',
    }),
  },
});

export type ServerAxiosRequestData = {
  sessionId: string | null;
  preferences: Preferences;
  headersToPropagate?: Record<string, string> | null;
};

// Attaches required query params and headers. This function should only accept parameters when called on the server!
export const getAxiosConfig = (serverAxiosRequestData?: ServerAxiosRequestData): AxiosRequestConfig => {
  // TODO: Move this to the interceptor on the client
  if (typeof window !== 'undefined') {
    const cookies = new Cookies(document.cookie);
    const [ckCookie, ccCookie, sessionId, countryCookie] = [
      cookies.get(AppCookies.CK),
      cookies.get(AppCookies.CC),
      cookies.get(AppCookies.SESSION),
      cookies.get(AppCookies.COUNTRY),
    ];

    const { siteId, language, currency } = PreferenceCookie.parseCookieCK(ckCookie);
    let isoCountry: string;

    // This cookie is b64 encoded and it's quite unclear which requests actually need this param.
    // TODO: Investigate if it can be removed globally
    try {
      const { CC: isoCode } = JSON.parse(window.atob(ccCookie));
      isoCountry = isoCode;
    } catch (e) {
      isoCountry = countryCookie ?? 'US';
    }

    const headers: AxiosRequestHeaders = new AxiosHeaders({
      ...(!!sessionId && {
        __session: sessionId,
      }),
    });

    return {
      headers,
      params: {
        isoCountry,
        'x-siteid': siteId,
        'x-language': language,
        'x-currency': currency,
      },
    };
  }

  // Parse serverAxiosRequestData on server to get all required headers & query params
  const { sessionId = null, headersToPropagate = null, preferences } = serverAxiosRequestData;
  const { currency, country, language, siteId } = preferences;

  const headers: AxiosRequestHeaders = new AxiosHeaders({
    ...(!!sessionId && {
      __session: sessionId,
    }),
    ...headersToPropagate,
  });

  return {
    headers,
    params: {
      isoCountry: country,
      'x-siteid': siteId,
      'x-language': language,
      'x-currency': currency,
    },
  };
};

const onRequest = (config: InternalAxiosRequestConfig) => {
  // abort all requests in jest tests
  if (process.env.JEST_CANCEL_API_REQUESTS === 'true') {
    const controller = new AbortController();
    const cfg = {
      ...config,
      signal: controller.signal,
    };
    controller.abort('Cancelled since we are in test environment');
    return cfg;
  }

  // /orders/ endpoints seem to be suffering from some bad performance, so we have a custom timeout of 15s set for them specifically.
  // Same for My account - Orders and Sales API, but orders/sales need more timeout
  const slowApiConfig = [
    { path: '/orders/', timeout: 15000 },
    { path: '/users/me/orders', timeout: 30000 },
    { path: '/users/me/soldItems', timeout: 30000 },
    { path: '/upload', timeout: 30000 },
  ];
  const slowApi = slowApiConfig.filter(({ path }) => config.url.includes(path))[0];

  if (slowApi != null) {
    config.timeout = slowApi.timeout;
  }
  return config;
};

// add a response interceptor to prevent errors from propagating in jest tests
if (process.env.JEST_CANCEL_API_REQUESTS === 'true') {
  axios.interceptors.response.use(
    (response) => {
      // Handle successful responses here, if necessary
      return response;
    },
    (error) => {
      // Prevent errors from propagating by returning a custom response
      return {
        data: {},
        status: 200,
        statusText: 'OK',
        headers: {},
        config: error.config,
      };
    },
  );
}

axios.interceptors.request.use(onRequest);

export { axios };
