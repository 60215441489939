import { LDFlagSet, LDFlagValue, LDOptions as LDOptionsType } from 'launchdarkly-js-client-sdk';
import Cookies from 'universal-cookie';
import { serialize } from 'cookie';
import Environment from '@config/index';
import { camelize } from '@helpers/utils/general';
import { GetServerSidePropsContext } from 'next';
import { AppCookies } from '@enums/cookies';
import { v4 as uuidV4 } from 'uuid';
import { LD_SERVER_ANONYMOUS_ID_HEADER, RANDOM_ANONYMOUS_ID_HEADER } from '@helpers/feature-flags/custom-headers';

let anonymousId = '';
let randomRangeId = '';

export const LDOptions: LDOptionsType = {
  evaluationReasons: true,
  sendEventsOnlyForVariation: true,
  sendLDHeaders: false,
};

export const camelizeFlagsAndMap = (object: LDFlagSet): [Record<string, LDFlagValue>, Record<string, string>] => {
  const flagsMaps: Record<string, string> = {};
  const camelizedObject = {};
  for (const kebabKey in object) {
    const camelizedKey = camelize(kebabKey);
    flagsMaps[camelizedKey] = kebabKey;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- any is coming from LDFlagValue
    camelizedObject[camelizedKey] = object[kebabKey];
  }
  return [camelizedObject, flagsMaps];
};

export const cookieOptions = {
  expires: new Date(Date.now() + 365 * 86400 * 1000),
  path: '/',
  domain: Environment.cookieOptions.domain,
  secure: true,
};

export const getOrCreateLDCookies = () => {
  if (typeof window === 'undefined') {
    return { anonymousId: undefined, randomRangeId: undefined };
  }

  const cookies = new Cookies();
  anonymousId = anonymousId || cookies.get<string | undefined>(AppCookies.ANONYMOUS_USER_ID_CSR);
  randomRangeId = randomRangeId || cookies.get<string | undefined>(AppCookies.RANDOM_RANGE_ID);

  if (!anonymousId) {
    anonymousId = uuidV4();
    cookies.set(AppCookies.ANONYMOUS_USER_ID_CSR, anonymousId, cookieOptions);
  }

  if (!randomRangeId) {
    randomRangeId = `anonymous_${Math.floor(Math.random() * 1000000) + 1}`;
    requestIdleCallback(() => {
      cookies.set(AppCookies.RANDOM_RANGE_ID, randomRangeId, cookieOptions);
    });
  }

  return {
    anonymousId,
    randomRangeId,
  };
};

// TODO: We should probs move the setting logic to _app and extraction to 'extractFromContext'
export const getOrCreateLDCookiesSsr = (ctx: GetServerSidePropsContext) => {
  const cookies = new Cookies(ctx.req.headers.cookie);
  const anonymousIdFromCookie = cookies.get<string | undefined>(AppCookies.ANONYMOUS_USER_ID_SSR);
  const anonymousIdFromServer = ctx.req.headers[LD_SERVER_ANONYMOUS_ID_HEADER] as string;

  // if cookie is not set return server value
  let serverAnonymousId = anonymousIdFromCookie || anonymousIdFromServer;

  // fallback: create a new one
  if (!serverAnonymousId) {
    serverAnonymousId = uuidV4();
    ctx.res?.setHeader('Set-Cookie', [serialize(AppCookies.ANONYMOUS_USER_ID_SSR, serverAnonymousId, cookieOptions)]);
  }

  const randomRangeIdFromCookie = cookies.get<string | undefined>(AppCookies.RANDOM_RANGE_ID);
  const randomRangeIdFromServer = ctx.req.headers[RANDOM_ANONYMOUS_ID_HEADER] as string;
  // if random id cookie is not set return server value
  const randomRangeId = randomRangeIdFromCookie || randomRangeIdFromServer;

  if (!anonymousId) {
    anonymousId = uuidV4();
    ctx.res?.setHeader('Set-Cookie', [serialize(AppCookies.RANDOM_RANGE_ID, randomRangeId, cookieOptions)]);
  }

  return { serverAnonymousId, randomRangeId };
};
