import { usePreferences } from '@context/preferences.context';
import { I18nLanguage } from '@interfaces/models/i18n';
import { Currency } from '@interfaces/models/currency';
import { Country } from '@interfaces/models/country';
import logger from '@helpers/utils/logger/client';
import useLocalizationQuery from './use-localization';

const useDisplayedPreference = () => {
  const { currency: cookieCurrency, country: countryCode, locale } = usePreferences();
  const { locales, countryList, currencies } = useLocalizationQuery();

  if (!cookieCurrency || !countryCode || !locale) {
    logger.info('User preferences found to be empty', {
      currency: cookieCurrency,
      country: countryCode,
      locale,
    });
  }

  const displayedLang: I18nLanguage = locales.find((data: I18nLanguage) => data.isoCode === locale) || {
    isoCode: locale,
    name: '',
  };
  const displayedCurrency: Currency = currencies.find((data: Currency) => data.isoCode === cookieCurrency) || {
    isoCode: cookieCurrency,
    symbol: '',
    name: '',
    id: '',
  };
  const displayedCountry = countryList.find((data: Country) => data.isoCode === countryCode) || {
    isoCode: countryCode,
    name: '',
    id: '',
  };

  return {
    displayedLang,
    displayedCurrency,
    displayedCountry,
  };
};

export default useDisplayedPreference;
