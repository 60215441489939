import { Country } from '@interfaces/models/country';
import botPattern from '@config/botPattern';
import { Env } from '@interfaces/models/environment';
import { QueryClient, Updater } from '@tanstack/react-query';
import { DeviceType } from '@enums/deviceType';
import { IContentTypeEntries } from '@constants/metas/default';
import { ContentStackComponentKeys } from '@enums/contentStack';

export const capitalize = (string: string): string => {
  return string.toLowerCase().replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
};

export const lowercaseFirstLetter = (str: string): string => {
  if (!str) {
    return '';
  }
  return str[0].toLowerCase() + str.slice(1);
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

/* istanbul ignore next  */
export const scrollTo = (selector: string): void => {
  const scrollTo =
    (document.querySelector(selector)?.getBoundingClientRect()?.top || 0) + document.documentElement.scrollTop;
  const headerHeight = document.querySelector('#header')?.getBoundingClientRect()?.height || 0;
  window.scrollTo({
    behavior: 'smooth',
    left: 0,
    top: scrollTo - headerHeight,
  });
};

export const invertMap = <K, V>(map: Map<V, K>): Map<K, V> => {
  return new Map<K, V>(Array.from(map, (keyValuePair: [V, K]) => keyValuePair.reverse() as [K, V]));
};

export const kebabCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

export const getCountryNameFromCountryISOCode = (countryList: Country[], iso: Country['isoCode']): Country['name'] => {
  /* istanbul ignore else */
  if (iso) {
    return countryList?.find(({ isoCode }) => isoCode === iso)?.name;
  }
};

export const startsWithNumber = (str: string): boolean => {
  return /^\d/.test(str);
};

export const startsWithLetter = (str: string): boolean => {
  return /^[A-Za-z]/.test(str);
};

export const camelize = (s: string) => s.replace(/[^a-zA-Z0-9]+(.)/g, (_, x: string) => x.toUpperCase());

export const clamp = (n: number, min: number, max: number): number => Math.min(Math.max(n, min), max);

export const debounce = <F extends (...args: Parameters<F>) => ReturnType<F>>(
  func: F,
  waitFor: number = 300,
): ((...args: Parameters<F>) => void) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>): void => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), waitFor);
  };
};

export const accentFold = (str: string): string => {
  return str.replace(
    /([àáâãäå])|([çčć])|([èéêë])|([ìíîï])|(ñ)|([òóôõöø])|(ß)|([ùúûü])|(ÿ)|(æ)/g,
    (str, a, c, e, i, n, o, s, u, y, ae) => {
      if (a) {
        return 'a';
      }
      if (c) {
        return 'c';
      }
      if (e) {
        return 'e';
      }
      if (i) {
        return 'i';
      }
      if (n) {
        return 'n';
      }
      if (o) {
        return 'o';
      }
      if (s) {
        return 's';
      }
      if (u) {
        return 'u';
      }
      if (y) {
        return 'y';
      }
      if (ae) {
        return 'ae';
      }
    },
  );
};

type ChainFunc<T> = (arg: T) => T;

export function chainMethods<T>(...funcs: ChainFunc<T>[]): ChainFunc<T> {
  return (arg: T) => funcs.reduce((acc, fn) => fn(acc), arg);
}

export function removeKeys<T extends object>(obj: T, keys: (keyof T)[]): Partial<T> {
  const newObj = { ...obj };
  keys.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
}

export function getRUMSessionSampleRate(datadogConfig: Env['datadog'], pageType: string): number {
  const regex = new RegExp(botPattern, 'i');
  const isErrPage = pageType === 'ErrorPage';
  return isErrPage ? 0 : regex.test(navigator.userAgent) ? datadogConfig.sampleRateBot : datadogConfig.sampleRate;
}

export const setQueryDataIfNotYetSet = <T,>(queryClient: QueryClient, key: string[], updater: Updater<T, T>): void => {
  if (queryClient.getQueryData<T>(key) === undefined) {
    queryClient.setQueryData<T>(key, updater);
  }
};

export const buildQueryString = (params: Record<string, string>) => {
  Object.keys(params).forEach((key) => !params[key] && delete params[key]);
  return new URLSearchParams(params);
};

export const getDeviceTypeFromUserAgent = (ua: string): DeviceType => {
  if (/Android/i.test(ua)) {
    return DeviceType.ANDROID;
  }
  /*
    its not accurate for some ios tablets
    https://github.com/faisalman/ua-parser-js/issues/690
    so, on client side, we overwrite it for edge cases: device-size.context.tsx -> useEffect
  */
  if (/iPad|iPhone|iPod/i.test(ua)) {
    return DeviceType.IOS;
  }
  return DeviceType.DESKTOP;
};

const headBannerComponents = [ContentStackComponentKeys.INFO_BANNER, ContentStackComponentKeys.INFO_BANNER_V2];

export const checkShouldShowBanner = (entries: IContentTypeEntries): boolean => {
  if (!entries || !entries.components || !entries.components.length) {
    return false;
  }
  return entries.components.some((e) => headBannerComponents.includes(e.type));
};
